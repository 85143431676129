import React, { useContext } from 'react';
import { BaseHeader } from '@latitude/base-header';
import { AnalyticsContext } from 'latitude-analytics';
import HeaderData from '../../data/json/Navigation/navigation.json';
import { renderBrandButton } from './BrandButton';
import { renderBannerContent } from './Banners';
import renderLoginModalImage from './LoginModalImage';
import { gemidString } from '../../utils/analyticsUtil';
import './_gem-header.scss';

// get GEM header data chunks
const navTreeData = HeaderData.navTree;
const loginModalsData = HeaderData.loginModals;
const loginFormData = HeaderData.loginForm;
const cardTypesData = HeaderData.cardTypes || [];
const searchFormData = HeaderData.searchForm;

const helpHref = HeaderData.navTree.items.find(
  item => item.id === 'nav-support'
).href;

function cardTypesByName(a, b) {
  const lhs = a.name.toLowerCase();
  const rhs = b.name.toLowerCase();
  if (lhs > rhs) {
    return 1;
  } else if (lhs < rhs) {
    return -1;
  }
  return 0;
}
cardTypesData.sort(cardTypesByName);

const GemHeader = props => {
  // append the gemid2 value on the 'help' button (CEPW-1060)
  const [analytics] = useContext(AnalyticsContext);
  if (analytics.pageData) {
    HeaderData.navTree.items.find(
      item => item.id === 'nav-support'
    ).href = `${helpHref}${gemidString(analytics, 'help', 'header')}`;
  }

  return (
    <BaseHeader
      navTreeData={navTreeData || {}}
      loginModalsData={loginModalsData || []}
      loginFormData={loginFormData || {}}
      cardTypesData={cardTypesData}
      searchFormData={searchFormData || {}}
      renderBrandButton={renderBrandButton}
      renderBanner={renderBannerContent}
      renderLoginModalImage={renderLoginModalImage}
      showLoansPane
      showCreditCardsUpload
      {...props}
    />
  );
};

export default GemHeader;
