// @flow

export const ANALYTICS_TEXT_SPACE_REPL = '-';

/**
 * getAppMeasurementInstance
 * @returns {Object}
 */
export const getAppMeasurementInstance = (t, pageName) => {
  return (
    (window.s_c_il &&
      window.s_c_il.filter(e => /gemoney/.test(e.s_account))[0]) ||
    null
  );
};

/**
 * directTrackPage - util wrapper method for 'AppMeasurement.t'
 * Use this method if you intend to fire page event inside a SPA
 * Adobe's docs: https://docs.adobe.com/content/help/en/analytics/implementation/javascript-implementation/function-tl.html
 *
 * @param pageName - page pageName text to be tracked
 */
export const directTrackPage = (pageName?: string = ''): void => {
  // get AppMeasurement instance
  const appMeasurement = getAppMeasurementInstance();
  if (appMeasurement === null || typeof appMeasurement.t !== 'function') {
    // exit if cannot find 'AppMeasurement' instance
    return;
  }

  // make tracking call
  const originalPageName = appMeasurement.pageName;
  appMeasurement.pageName = `${originalPageName.toString()}:${pageName}`;
  appMeasurement.t();

  // revert back to original page name
  appMeasurement.pageName = originalPageName;
};

/**
 * directTrackLink - util wrapper method for 'AppMeasurement.tl'
 * Use this method if you intend to fire link event inside a SPA
 *
 * Adobe's docs: https://docs.adobe.com/content/help/en/analytics/implementation/javascript-implementation/function-tl.html
 *
 * @param domTrigger - dom element that trigger's the direct call
 * @param linkType - link type: 'd|e|o'
 * @param linkName - link name to be tracked
 * @param variableOverrides
 * @param doneAction
 */
export const directTrackLink = (
  domTrigger: EventTarget | true,
  linkType: string,
  linkName: string,
  variableOverrides?: null | {} = null,
  doneAction?: null | (() => void) = null
): void => {
  // get AppMeasurement instance
  const appMeasurement = getAppMeasurementInstance();
  if (appMeasurement === null || typeof appMeasurement.tl !== 'function') {
    // call doneAction right away
    if (typeof doneAction === 'function') {
      doneAction();
    }
    // exit if cannot find 'AppMeasurement' instance
    return;
  }

  // normalise params
  let trigger = domTrigger;
  if (trigger === undefined || trigger === null) {
    // if no target is provided, use 'true', which skips the default tracking call delay
    trigger = true;
  }
  const type = linkType || 'o';
  const name = linkName || '';

  // make tracking call
  appMeasurement.tl(trigger, type, name, variableOverrides, doneAction);
};

/**
 * normaliseForAnalytics - normalise text value for analytics text usage
 * @param {string} text - original text to be normalised
 * @returns {string}
 */
export const normaliseForAnalytics = (text: string): string => {
  if (typeof text !== 'string' || text.trim().length === 0) {
    return '';
  }

  let normalisedText = text.trim();

  // 1. need to replace all space characters with replacement
  normalisedText = normalisedText.replace(/\s/g, ANALYTICS_TEXT_SPACE_REPL);
  // 2. to lowercase
  normalisedText = normalisedText.toLowerCase();

  return normalisedText;
};

/**
 * gemidString - generates a gemdid2 querystring based on analytics context
 * @param {object} context  - analytics context
 * @param {sring} label
 * @param {string} location
 * @returns {string}
 */
export const gemidString = (
  analytics: object,
  label: string,
  location: string
) => {
  const site = normaliseForAnalytics(analytics.siteData.page.site);
  const siteSection = normaliseForAnalytics(
    analytics.pageData.page_siteSection
  );
  const pageTitle = normaliseForAnalytics(analytics.pageData.page_pageTitle);
  const formattedLabel = normaliseForAnalytics(label);
  const formattedLocation = normaliseForAnalytics(location);

  const str = `?gemid2=${site}&${siteSection}&${pageTitle}&${formattedLabel}&${formattedLocation}`;

  return str;
};
