import { getUrlFromEnv } from '../../../utils/getUrlFromEnvUtil';

const brokerHeaderData = {
  site: 'Latitude',
  modalItems: [
    {
      heading: 'Get a Personal Loan quote',
      description:
        'Easily check your client\'s Personal Loan interest rate and then submit an application',
      button: {
        label: 'Get rate estimate',
        href: getUrlFromEnv('funnel-start-broker-soft-quote'),
        target: '_self',
        action: 'quote-link',
        category: 'cta'
      }
    },
    {
      heading: 'Continue Personal Loan application',
      description:
        'Log in to the Broker Dashboard to review a client\'s rate and submit an application',
      button: {
        label: 'Log in here',
        href: getUrlFromEnv('funnel-start-broker-soft-quote'),
        action: 'quote-link',
        category: 'cta',
      },
    }
  ]
};

export default brokerHeaderData;
