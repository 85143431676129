import React, { useEffect, useRef, useState } from 'react';
import { ANALYTICS } from '@latitude/core/utils/constants';
import {
  AnalyticsContext,
  AnalyticsLocationProvider,
} from 'latitude-analytics';
import BrandButton from './BrandButton';
import BrokerLoginButton from './BrokerLoginButton';
import BrokerLoginModal from './BrokerLoginModal';
import {
  BrokerHeaderWrapper,
  BrokerHeaderInnerWrapper,
} from './BrokerHeader.styled';

const BrokerHeader = ({ headerData }) => {
  const [isModalActive, setIsModalActive] = useState(false);
  const wrapperRef = useRef(null);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsModalActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <AnalyticsLocationProvider location={ANALYTICS.LOCATION.HEADER}>
      <AnalyticsContext.Consumer>
        {([analytics]) => (
          <BrokerHeaderWrapper data-sticky-navigation-offset>
            <BrokerHeaderInnerWrapper ref={wrapperRef}>
              <BrandButton site={headerData.site}/>
              <BrokerLoginButton
                isModalActive={isModalActive}
                setIsModalActive={setIsModalActive}
                analytics={analytics}
              />
              <BrokerLoginModal
                isModalActive={isModalActive}
                modalItems={headerData.modalItems}
              />
            </BrokerHeaderInnerWrapper>
          </BrokerHeaderWrapper>
        )}
      </AnalyticsContext.Consumer>
    </AnalyticsLocationProvider>
  );
};

export default BrokerHeader;
