// @flow
import classnames from 'classnames';
import * as React from 'react';
import { StyledLink } from '@latitude/link/Link.styled';
import SvgInline from '../SvgInline/SvgInline';
import './_home-button.scss';

/**
 * Renders header brand button element
 *
 * @param {boolean} isSearchOpen - if header search UI is activated
 * @returns {React.element} rendered brand button
 */
export const renderBrandButton = (isSearchOpen: boolean) => (
  <BrandButton mobileVisible={isSearchOpen} />
);

type Props = {
  className?: string,
  mobileVisible: boolean // Is the button visible on mobile
};

const BrandButton = (props: Props) => {
  const { className, mobileVisible } = props;
  return (
    <StyledLink
      className={classnames('home-button trackcustomanalytics', className, {
        'mobile-visible': mobileVisible
      })}
      href="/"
      data-trackid="nav-home"
    >
      <SvgInline
        name="gem-latitude-logo-blue"
        aria-label="Home page"
        className="home-button__logo"
        fillBlueBright
      />
    </StyledLink>
  );
};

export default BrandButton;
