import * as React from 'react';
import { BrandButtonWrapper } from './BrokerHeader.styled';
import SvgInline from '../SvgInline/SvgInline';

const BrandButton = ({ site }) => {return (
  <BrandButtonWrapper
    noStyle
    href="/"
    trackId="nav-home"
    trackEventData={{
      label: site,
    }}
    aria-label="Home page"
  >
    <SvgInline
      name="gem-by-latitude-logo-white"
      aria-label="Home page"
      className="home-button__logo"
      fillWhite
    />
  </BrandButtonWrapper>
)};

export default BrandButton;
