import styled from 'styled-components';
import {
  BREAKPOINT,
  COLOR,
  FONT_FAMILY,
  FONT_SIZE,
} from '@latitude/core/utils/constants';
import { Link } from '@latitude/link';
import SvgInline from '@latitude/svg-inline';
import BlueHeader from './Images/Blue_header.png';

/* Broker Header */
export const BrokerHeaderWrapper = styled.header`
  height: 80px;
  background-color: #0047b0;
  background-image: linear-gradient(to right, #0047b0 50%, #0155db 50%);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000;

  @media (max-width: ${BREAKPOINT.LG}) {
    height: 56px;
  }
`;

export const BrokerHeaderInnerWrapper = styled.div`
  position: relative;
  height: 100%;
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;

  &:after {
    content: '';
    display: block;
    position: fixed;
    width: 100%;
    height: 80px;
    background-image: url(${BlueHeader});
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: 50% 100%;
    z-index: -2;
    top: 0;
    left: 0;
    pointer-events: none;

    @media (max-width: ${BREAKPOINT.LG}) {
      height: 56px;
      background-position: 90% 80%;
      background-size: auto;
    }

    @media (max-width: ${BREAKPOINT.SM}) {
      height: 56px;
      background-position: 100% 80%;
      background-size: auto;
    }
  }
`;

/* Brand Button */
export const BrandButtonSvg = styled(SvgInline)`
  width: 100%;
  path {
    fill: ${COLOR.WHITE};
  }
`;

export const BrandButtonWrapper = styled(Link)`
  display: flex;
  align-items: center;
  width: 122px;
  margin-left: 20px;
  margin-right: 6px;

  @media (min-width: ${BREAKPOINT.SM}) {
    width: 170px;
  }
  @media (min-width: ${BREAKPOINT.LG}) {
    width: 202px;
    margin-left: 20px;
  }
`;

/* BrokerLoginButton */
export const BrokerLoginButtonWrapper = styled.div`
  width: 160px;
  height: 100%;

  @media (max-width: ${BREAKPOINT.LG}) {
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;

    .is-active {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
`;

export const LoginButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  height: 100%;

  .is-active {
    border-bottom: 4px solid white;
    border-top: 4px solid #0047b0;
  }

  &:focus {
    outline: 0;
  }
  @media (max-width: ${BREAKPOINT.LG}) {
    font-size: 16px;
    border: none;
  }
`;

export const LoginButtonSvg = styled(SvgInline)`
  path {
    fill: white;
  }

  @media (max-width: ${BREAKPOINT.LG}) {
    display: none;
  }
`;

/* Broker Login Modal */
export const BrokerLoginModalWrapper = styled.div`
  z-index: -5;
  position: absolute;
  top: 56px;
  padding: 15px;
  right: -100%;
  width: 100%;
  background-color: white;
  transform: translateX(0);
  transition: transform 0.35s cubic-bezier(0.31, 0.005, 0.735, 0.01);
  box-shadow: none;
  border: 1px solid #f3f3f3;

  &.is-open {
    transform: translateX(-100%);
    transition: transform 0.5s cubic-bezier(0.235, 0.79, 0.65, 1);
    box-shadow: 0 0 0 2038px rgba(0, 0, 0, 0.5);
  }

  @media (min-width: ${BREAKPOINT.MD}) {
    right: -390px;
    top: 56px;
    transform: translateX(0);
    transition: transform 0.35s cubic-bezier(0.31, 0.005, 0.735, 0.01);
    width: 390px;
    box-shadow: none;

    &.is-open {
      transform: translateX(-100%);
      transition: transform 0.5s cubic-bezier(0.235, 0.79, 0.65, 1);
      box-shadow: 0 0 0 2038px rgba(0, 0, 0, 0.5);
    }
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    position: absolute;
    right: 11px;
    top: 0px;
    transform: translateY(-100%);
    transition: transform 0.35s cubic-bezier(0.31, 0.005, 0.735, 0.01);

    &.is-open {
      transform: translateY(80px);
      transition: transform 0.5s cubic-bezier(0.235, 0.79, 0.65, 1);
      box-shadow: 0 4px 6px -2px rgb(0 0 0 / 25%);
    }
  }

  > * {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
`;

export const ModalItem = styled.div`
  background-color: ${COLOR.GREY5};
  padding: 15px;
  h5 {
    font-family: ${FONT_FAMILY.TITLE};
    font-size: ${FONT_SIZE.SMALL};
    color: ${COLOR.BLUE_DEEP};
    margin-top: 0;
  }
`;

export const ModalButton = styled(Link)`
  color: ${COLOR.BLUE_DEEP};
  font-weight: bold;
  font-size: 15px;
  border-bottom: 1px solid ${COLOR.BLUE_DEEP};

  &:hover {
    text-shadow: 0 0 1px ${COLOR.BLUE_DEEP};
    text-decoration: none;
  }
`;
