import React from 'react';
import classnames from 'classnames';

// const reqSvgs = require.context('../../images/inline-svg', true, /\.svg$/);
// const Svgs = reqSvgs.keys().reduce((images, path) => {
//   // remove path and extention from filename
//   let name = path.replace(/^.*[\\\/]/, '').replace(/\.[^/.]+$/, '');
//   images[name] = reqSvgs(path);
//   return images;
// }, {});

export default props => {
  const Svg = require(`../../images/inline-svg/${props.name}.svg`);
  const className = classnames(
    props.className,
    { 'svg--stroke-white': props.strokeWhite },
    { 'svg--stroke-grey': props.strokeGrey },
    { 'svg--stroke-grey-dark': props.strokeGreyDark },
    { 'svg--stroke-grey-darker': props.strokeGreyDarker },
    { 'svg--stroke-blue-bright': props.strokeBlueBright },
    { 'svg--stroke-blue-dark': props.strokeBlueDark },
    { 'svg--fill-blue-bright': props.fillBlueBright },
    { 'svg--fill-grey': props.fillGrey },
    { 'svg--fill-grey-dark': props.fillGreyDark },
    { 'svg--fill-none': props.fillNone },
    { 'svg--fill-white': props.fillWhite },
    { 'svg--small': props.small }
  );

  return <Svg className={className} style={props.style} />;
};
