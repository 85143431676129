import * as React from 'react';
import classnames from 'classnames';
import { trackEvent } from 'latitude-analytics';
import {
  BrokerLoginButtonWrapper,
  LoginButton,
  LoginButtonSvg,
} from './BrokerHeader.styled';

const BrokerLoginButton = ({
  isModalActive,
  setIsModalActive,
  analytics,
}) => {
  const handleOnClick = () => {
    if (trackEvent) {
      trackEvent(analytics, {
        category: 'internal-link',
        action: 'login',
        label: 'broker login',
        location: 'header',
      });
    }
    setIsModalActive(!isModalActive);
  };

  return (
    <BrokerLoginButtonWrapper
      className={classnames(isModalActive && 'is-active')}
    >
      <LoginButton
        onClick={handleOnClick}
        className={classnames(isModalActive && 'is-active')}
        type="button"
        aria-label="Broker login"
      >
        <div>Broker Log in</div>
        <LoginButtonSvg
          aria-label="Down"
          name={isModalActive ? 'chevron-up' : 'chevron-down'}
          className="up-down-icon"
        />
      </LoginButton>
    </BrokerLoginButtonWrapper>
  );
};

export default BrokerLoginButton;
