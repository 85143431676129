import { FUNNEL_URL } from './constants';

const env = process.env.GATSBY_TEALIUM_ENV || 'dev';

const URLS = {
  'funnel-pl-start-application-button': {
    dev: FUNNEL_URL.PL.DEV,
    qa: FUNNEL_URL.PL.QA,
    prod: FUNNEL_URL.PL.PROD
  },
  'funnel-start-soft-quote': {
    dev: FUNNEL_URL.SQ.DEV,
    qa: FUNNEL_URL.SQ.QA,
    prod: FUNNEL_URL.SQ.PROD
  },
  'funnel-start-loans-soft-quote': {
    dev: FUNNEL_URL.SQ.DEV_LOANS,
    qa: FUNNEL_URL.SQ.QA_LOANS,
    prod: FUNNEL_URL.SQ.PROD_LOANS
  },
  'funnel-eapps-start-application': {
    dev: FUNNEL_URL.EAPPS.DEV,
    qa: FUNNEL_URL.EAPPS.QA,
    prod: FUNNEL_URL.EAPPS.PROD
  },
  'funnel-osc': {
    dev: FUNNEL_URL.OSC.DEV,
    qa: FUNNEL_URL.OSC.QA,
    prod: FUNNEL_URL.OSC.PROD
  },
  'funnel-start-broker-soft-quote': {
    dev: FUNNEL_URL.SQ_BROKER.DEV,
    qa: FUNNEL_URL.SQ_BROKER.QA,
    prod: FUNNEL_URL.SQ_BROKER.PROD,
  }
};

export function getUrlFromEnv (urlId, queryString = '') {
  const url = URLS[urlId][env];

  if (url) {
    return url + queryString;
  }

  throw new Error(`${urlId} URL doesn't exist in ${env} environment`);
}
