import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from '@latitude/link';
import { COLOR } from '@latitude/core/utils/constants';
import { Banner } from '@latitude/banner';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';

const StyledLink = styled(Link)`
  color: ${COLOR.WHITE}!important;
  &:hover {
    color: ${COLOR.WHITE}!important;
    text-decoration: none;
  }
`;

const OPTIONS = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => (
      <StyledLink
        variant={COLOR.WHITE}
        href={node.data.uri}
        trackEventData={{ location: 'banner' }}
      >
        {children}
      </StyledLink>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p style={{ margin: 0 }}>{children}</p>
    )
  }
};

const checkDateExpired = date => {
  if (!date) return false;
  const expiredDate = new Date(date);
  const now = Date.now();
  return now > expiredDate;
};

const Alert = ({ message, siteName, visible, showCloseIcon, expireDate }) => {
  const [isVisible, setIsVisible] = useState(visible);
  const [isClose, setIsClose] = useState(showCloseIcon);

  useEffect(() => {
    if (
      sessionStorage.getItem(siteName + '_closeStatus') === 'true' ||
      checkDateExpired(expireDate)
    ) {
      setIsClose(false);
      setIsVisible(false);
    }
  }, []);

  const handleClose = () => {
    if (isClose) {
      setIsClose(false);
      setIsVisible(false);
      sessionStorage.setItem(siteName + '_closeStatus', true);
    }
  };

  return isVisible ? (
    <Banner onClose={isClose && handleClose} showClose={isClose}>
      {documentToReactComponents(message, OPTIONS)}
    </Banner>
  ) : (
    <></>
  );
};

export default Alert;
