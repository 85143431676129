// @flow
import * as React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { withEventTracking } from 'react-event-tracker';
import { Link } from '@latitude/link';
import {
  BREAKPOINT,
  BUTTON_STYLE,
  COLOR,
  FONT_FAMILY,
  FONT_WEIGHT
} from '@latitude/core/utils/constants';

/**
 * Base banner
 */
const BaseBanner = (props: any) => {
  const classNames = classnames(props.className, 'nav-base-banner');
  const imageFile = props.imageFile || null;
  const titleContent = props.title || null;
  const descContent = props.desc || null;
  const ctaLeft = props.ctaLeft || null;
  const ctaRight = props.ctaRight || null;
  const hasCta = ctaLeft || ctaRight;
  return (
    <div className={classNames}>
      {imageFile && (
        <div className="nav-base-banner__image-wrapper">
          <img className="nav-base-banner__image" src={imageFile} alt="" />
        </div>
      )}
      {titleContent && (
        <h3 className="nav-base-banner__title">{titleContent}</h3>
      )}
      {descContent && <p className="nav-base-banner__desc">{descContent}</p>}
      {hasCta && (
        <div className="nav-base-banner__cta-group">
          {ctaLeft}
          {ctaRight}
        </div>
      )}
    </div>
  );
};

/**
 * Abstract base banner with styles
 */
export const StyledBaseBanner = styled(BaseBanner)`
  // styles
  color: white;
  background: ${COLOR.BLUE_DEEP};

  // box model
  padding: 26px 22px;
  height: auto;
  overflow: hidden;

  // flex layout
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > .nav-base-banner__title,
  > .nav-base-banner__desc,
  > .nav-base-banner__cta-group {
    position: relative;
    flex: 0 0 auto;
  }

  // image
  .nav-base-banner__image-wrapper {
    display: block;
    position: relative;
    flex: 1 0 100px;
    width: auto;
    min-width: 100%;
    height: auto;
    margin: -26px -22px 0;
    min-height: 10px;
    max-height: 170px;
    overflow: visible;

    img.nav-base-banner__image {
      display: block;
      position: absolute;
      top: -2px; // buffer for transparent edge
      right: -2px; // buffer for transparent edge
      width: 100%;
      max-width: calc(100% - 45px);
    }
  }

  // title
  .nav-base-banner__title {
    color: white;
    font-size: 20px;
    line-height: 24px;
    font-family: ${FONT_FAMILY.TITLE};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    letter-spacing: -1px;
    margin: 0;

    & + .nav-base-banner__desc {
      margin-top: 8px;
    }
  }

  // description
  .nav-base-banner__desc {
    color: white;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
  }

  // ctas
  .nav-base-banner__cta-group {
    margin-top: 12px;

    a.nav-base-banner__cta {
      display: inline-flex;
      max-width: calc(50% - 8px);
      min-width: auto;
      width: 50%;
      height: 45px;
      margin: 0;
      padding: 0 2px; // reduced to accommodate more text
      font-size: 16px;

      &:last-child {
        margin-left: 16px;
      }
      &:only-child {
        width: auto;
        max-width: 100%;
        margin-left: 0;
      }

      &:hover {
        border-bottom: 1px solid;
      }
    }
  }
`;

/**
 * Loans banner
 */
const StyledLoansBanner = styled(StyledBaseBanner)`
  // styles
  background-image: url(${require('./images/bg-banner-loans.svg')}),
    linear-gradient(115deg, ${COLOR.PURPLE_DARK}, #6d56d3);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  // box model
  margin-top: 16px;
  padding: 16px 24px;
  max-height: 230px;

  // image
  .nav-base-banner__image-wrapper {
    display: none;
  }

  // title
  .nav-base-banner__title {
    font-size: 19px;
    line-height: 23px;
  }

  // description
  .nav-base-banner__desc {
    font-size: 15px;
    line-height: 19px;
  }

  // ctas
  .nav-base-banner__cta-group {
    a.nav-base-banner__cta {
      height: 40px;
      font-size: 15px;
    }
  }
`;

/**
 * Credit card banner
 */
const StyledCreditCardBanner = styled(StyledBaseBanner)`
  background-image: url(${require('./images/bg-banner-cc.png')});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  max-height: 420px;

  @media (min-width: ${BREAKPOINT.XL}) {
    max-height: 400px;
  }

  .nav-base-banner__image-wrapper {
    flex: none;
    width: 100%;
    min-width: 0;
    height: auto;
    margin: -8px auto 16px auto;
    max-height: none;

    img.nav-base-banner__image {
      position: static;
      top: auto;
      right: auto;
      width: 174px;
      height: 148px;
      max-width: none;
      margin: 0 auto;
    }
  }

  .nav-base-banner__title {
    color: ${COLOR.BLACK};
    font-size: 28px;
    line-height: 32px;
  }

  .nav-base-banner__desc {
    color: ${COLOR.BLACK};
    font-size: 16px;
    line-height: 22px;
    font-weight: ${FONT_WEIGHT.MEDIUM};
  }

  @media (max-width: 1199px) {
    .nav-base-banner__desc {
      font-size: 15px;
    }
  }

  .nav-base-banner__cta-group {
    margin-top: 16px;

    a.nav-base-banner__cta {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
`;

/**
 * Insurance banner
 */
const StyledInsuranceBanner = styled(StyledBaseBanner)`
  background: #8471db;
  max-height: 420px;

  .nav-base-banner__desc {
    font-weight: ${FONT_WEIGHT.LIGHT};
    letter-spacing: -0.4px;
  }
`;


/*
 * Banner class. Renders a custom banner with the given ID inside the header
 */
type Props = {
  id: string
};
const Banner = (props: Props) => {
  switch (props.id) {
    case 'banner-loans':
      return (
        <StyledLoansBanner
          title={<>Limited time 12.99% fixed p.a. Personal Loan offer</>}
          desc={
            <>
              Move it from 'to do' to done' with this great rate on new personal
              loans $20k or more. Ends 27/03/20.
            </>
          }
          ctaLeft={
            <Link
              className="nav-base-banner__cta"
              button={BUTTON_STYLE.PRIMARY}
              href="/loans/apply"
              data-trackid="nav-banner-pl-offer-apply"
              trackProductId={['PLNZLF-WEB']}
            >
              Apply now
            </Link>
          }
          ctaRight={
            <Link
              className="nav-base-banner__cta"
              button={BUTTON_STYLE.TERTIARY_INVERSE}
              href="/fixed-rate-loans/"
              data-trackid="nav-banner-pl-offer-more"
              trackProductId={['PLNZLF-WEB']}
            >
              Find out more
            </Link>
          }
        />
      );
    case 'banner-credit-cards':
      return (
        <StyledCreditCardBanner
          imageFile={require('./images/bg-banner-cc-new.png')}
          title={
            <>
              Let's shop with
              <br />
              Gem Visa
            </>
          }
          desc={
            <>
              Swipe some awesome deals with your Gem Visa and get cash back credit.
            </>
          }
          ctaLeft={
            <Link
              className="nav-base-banner__cta"
              button={BUTTON_STYLE.SECONDARY}
              href="https://www.offers-exchange.com/gemvisa"
              data-trackid="nav-banner-gem-visa-more"
              trackProductId={['CCNZGEM']}
            >
              Find out more
            </Link>
          }
        />
      );
    case 'banner-insurance':
      return (
        <StyledInsuranceBanner
          imageFile={require('./images/bg-banner-pl-offer.png')}
          title={
            <>
              Limited time
              <br />
              12.99% fixed p.a.
              <br />
              Personal Loan offer
            </>
          }
          desc={
            <>
              Let&apos;s move it from &apos;to do&apos; to &apos;done&apos; with
              this great rate on all new personal loans $20k or more. Offer ends
              17/12/20.
            </>
          }
          ctaLeft={
            <Link
              className="nav-base-banner__cta"
              button={BUTTON_STYLE.TERTIARY_INVERSE}
              href="/fixed-rate-loans/"
              data-trackid="nav-banner-pl-offer-more"
              trackProductId={['PLNZLF-WEB']}
            >
              Find out more
            </Link>
          }
          ctaRight={
            <Link
              className="nav-base-banner__cta"
              button={BUTTON_STYLE.PRIMARY}
              href="/loans/apply"
              data-trackid="nav-banner-pl-offer-apply"
              trackProductId={['PLNZLF-WEB']}
            >
              Apply now
            </Link>
          }
        />
      );
    default:
      return null;
  }
};

/**
 * Renders banner content based on provided id
 *
 * @param {string} bannerId - identifier of banner so will return matching banner element
 * @param {number} key - react key id
 * @returns {React.element} rendered banner content
 */
// eslint-disable-next-line import/prefer-default-export
export const renderBannerContent = (bannerId: string, key?: number) => {
  if (bannerId && bannerId.trim().length !== 0) {
    const BannerWithTracking = withEventTracking(Banner, {
      trackEvent: () => {},
      trackEventAndStoreTrafficSource: () => {},
      getTrackingQueryString: () => ''
    });
    return <BannerWithTracking id={bannerId} key={key} />;
  }
  return null;
};
