import React from 'react';
import classnames from 'classnames';
import {
  BrokerLoginModalWrapper,
  ModalItem,
  ModalButton,
} from './BrokerHeader.styled';


const BrokerLoginModal = ({ isModalActive, modalItems }) => {
  return (
    <BrokerLoginModalWrapper
      className={classnames({
        'is-open': isModalActive,
      })}
    >
      {modalItems &&
        modalItems.map(({ heading, description, button, button2 }) => (
          <ModalItem>
            <h5>{heading}</h5>
            <p>{description}</p>
            <ModalButton
              aria-label={button.label}
              href={button.href}
              trackEventData={{
                action: button.action,
                category: button.category,
              }}
              target={button.target}
            >
              {button.label}
            </ModalButton>
            {heading === 'Continue Personal Loan application' && button2 && 
            <>
            <br /><br />
            <ModalButton
              aria-label={button2.label}
              href={button2.href}
              trackEventData={{
                action: button2.action,
                category: button2.category,
              }}
              target={button2.target}
            >
              {button2.label}
            </ModalButton>
            </>
}
          </ModalItem>
        ))}
    </BrokerLoginModalWrapper>
  );
};

export default BrokerLoginModal;
